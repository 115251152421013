// ConsolePage.tsx

import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { RealtimeClient, ItemType } from '@openai/realtime-api-beta';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { generateInstructions } from '../utils/conversation_config';
import { WavRenderer } from '../utils/wav_renderer';
import { Mic } from 'react-feather';

import { useAuth } from '../context/AuthContext'; // Ajouter cet import

import React from 'react';

import SignUpForm from '../components/SignUpForm';

import { useTranslation } from 'react-i18next'; // Ajouter cet import
import i18next from 'i18next'; // Ajoutez cet import

import { MESSAGE_LIMITS, SPECIAL_USERS } from '../config/limits';

import * as Sentry from "@sentry/react"; // Ajouter cet import

declare const gtag: (...args: any[]) => void;


type ButtonEvent = React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>;



type ConversationItemType = ItemType & {
  translation?: string;
};

// Ajouter l'interface pour accepter la prop nativeLanguage
interface ConsolePageProps {
  nativeLanguage: string;
  studyLanguage: string;
  studyLevel: number;
  selectedScenario: string; // Ajouter le scénario sélectionné
}
// Définition de LOCAL_RELAY_SERVER_URL
const LOCAL_RELAY_SERVER_URL: string = process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || 'http://localhost:8082/relay';


// Définition de API_BASE_URL
const API_BASE_URL: string =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8082';

  function preventLineBreaks(text: string | undefined): string {
    if (!text) return '';
    return text.replace(/ (\?|!|:|;)/g, '\u00A0$1');
  }
  


  export function ConsolePage({
    nativeLanguage,
    studyLanguage,
    studyLevel,
    selectedScenario,
  }: ConsolePageProps) {
    const { t } = useTranslation(); // Ajouter l'utilisation de useTranslation
    const { currentUser } = useAuth(); // Récupérer l'utilisateur courant
    const [sessionMessageCount, setSessionMessageCount] = useState(0); // Renommage du state
    const [totalMessageCount, setTotalMessageCount] = useState(0); // Nouveau state
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Nouvel état

    // Ajouter cet état avec les autres états
    const [isLimitReached, setIsLimitReached] = useState(false);
    const [isClientConnected, setIsClientConnected] = useState(false);


// Fonction gtag_report_conversion pour gérer la conversion
function gtag_report_conversion(url?: string) {
  const callback = function () {
    if (typeof(url) != 'undefined') {
      window.location.href = url;
    }
  };
  gtag('event', 'conversion', {
    'send_to': 'AW-960724066/F2Y3CLDoxuYZEOL4jcoD',
    'value': 0.5,
    'currency': 'EUR',
    'event_callback': callback
  });
  return false;
}

// Fonction de succès de l'inscription
const handleSignUpSuccess = useCallback(() => {
  setShowSignUpForm(false);
  setShowSuccessMessage(true);

  console.log("Event de conversion Google Ads déclenché via gtag_report_conversion");

  // Déclenche la conversion
  gtag_report_conversion();

  setTimeout(() => setShowSuccessMessage(false), 5000);
}, []);
    

    const hasSentStartRef = useRef(false);

    const [items, setItems] = useState<ConversationItemType[]>([]);
    const [error, setError] = useState<string | null>(null);

    const [userSentenceCount, setUserSentenceCount] = useState(0);

    /* Commentaire du son
    const completionSound = useRef(new Audio('/sounds/New-user-phrase-sound-effect.mp3'));
    */


    const dynamicInstructions = useMemo(() => {
      return generateInstructions(
        nativeLanguage,
        studyLanguage,
        studyLevel,
        selectedScenario
      );
    }, [nativeLanguage, studyLanguage, studyLevel, selectedScenario]);
    

    /**
     * Instantiate:
     * - WavRecorder (speech input)
     * - WavStreamPlayer (speech output)
     * - RealtimeClient (API client)
     */
    const wavRecorderRef = useRef<WavRecorder>(
      new WavRecorder({ sampleRate: 24000 })
    );
    const wavStreamPlayerRef = useRef<WavStreamPlayer>(
      new WavStreamPlayer({ sampleRate: 24000 })
    );
    const clientRef = useRef<RealtimeClient>(
      new RealtimeClient({ url: LOCAL_RELAY_SERVER_URL })
    );

    useEffect(() => {
      const client = clientRef.current;
    
      // Définir le type de l'erreur
      const handleError = (error: Error) => {
        console.error('❌ Connection error:', error);
        setError(`Erreur de connexion au serveur : ${error.message || error}`);
        setIsClientConnected(false);
      };

      const handleConnected = () => {
        console.log('✅ Client connected');
        setIsClientConnected(true);
        setError(null);
      };

      const handleDisconnected = () => {
        console.log('🔌 Client disconnected');
        setIsClientConnected(false);
      };

      // Ajouter les listeners
      client.on('connected', handleConnected);
      client.on('disconnected', handleDisconnected);
      client.on('error', handleError);

      // Cleanup : utiliser la méthode reset() au lieu de removeListener
      return () => {
        client.reset(); // Cette méthode nettoie tous les listeners
      };
    }, []); 

    const clientCanvasRef = useRef<HTMLCanvasElement>(null);
    const serverCanvasRef = useRef<HTMLCanvasElement>(null);


    const [isWavStreamPlayerConnected, setIsWavStreamPlayerConnected] = useState(false);


    const [canPushToTalk] = useState(true);
    const [isRecording, setIsRecording] = useState(false);

    /**
     * Connect to conversation:
     * WavRecorder taks speech input, WavStreamPlayer output, client is API client
     */

      // Ajouter un log pour vérifier les paramètres passés à ConsolePage
      useEffect(() => {
        console.log("Langue maternelle :", nativeLanguage);
        console.log("Langue d'étude :", studyLanguage);
        console.log("Niveau d'étude :", studyLevel);
        console.log("Scénario sélectionné :", selectedScenario);
      }, [nativeLanguage, studyLanguage, studyLevel, selectedScenario]);


      const connectConversation = useCallback(async () => {
        const client = clientRef.current;
      
        // Vérifie si le client est déjà connecté
        if (client.isConnected()) {
          console.log("Log: The client is already connected. No further action needed.");
          return; // Arrête l'exécution si déjà connecté
        }
      
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;
      
        try {
          // Connexion au microphone
          try {
            const recorderStatus = wavRecorder.getStatus();
            if (recorderStatus === 'recording') {
              console.log("Log: Microphone already recording.");
            } else {
              await wavRecorder.begin();
            }
          } catch (error: any) {
            Sentry.captureException(error, {
              tags: {
                location: 'ConsolePage - connectConversation - Microphone',
                userAgent: navigator.userAgent,
              }
            });

            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
              setError('Microphone access denied. Please enable microphone access in your browser settings.');
            } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
              setError('No microphone found. Please connect a microphone to your device.');
            } else if (error.message && error.message.includes('Already connected')) {
              console.log("Log: Microphone already connected.");
            } else {
              setError(`Unknown error accessing the microphone: ${error.message || error}`);
            }
            return;
          }
      
          // Connexion à la sortie audio
          try {
            console.log("Log: Attempting to connect to audio output.");
            if (isWavStreamPlayerConnected) {
              console.log("Log: Audio output already connected.");
            } else {
              await wavStreamPlayer.connect();
              setIsWavStreamPlayerConnected(true); // Mettre à jour l'état
            }
          } catch (error: any) {
            Sentry.captureException(error, {
              tags: {
                location: 'ConsolePage - connectConversation - AudioOutput',
                userAgent: navigator.userAgent,
              }
            });
            console.error("Error connecting to audio output:", error.message || error);
            setError(`Error connecting to audio output: ${error.message || error}`);
            return; // Arrête l'exécution si la connexion à la sortie audio échoue
          }
          
      
          // Connexion au client
          try {
            console.log("Log: Connecting to the client at URL:", LOCAL_RELAY_SERVER_URL);
            await client.connect();
            console.log("Log: Client connected successfully.");
            setIsClientConnected(true);
          } catch (error: any) {
            Sentry.captureException(error, {
              tags: {
                location: 'ConsolePage - connectConversation - Client',
                userAgent: navigator.userAgent,
              }
            });
            if (error.message === "Already connected, use .disconnect() first") {
              console.log("Log: Attempted to reconnect to an already active client. Ignoring.");
              setError(null); // Efface toute erreur si la connexion est déjà active
              setIsClientConnected(true);
            } else {
              console.error("Error connecting to client:", error.message || error);
              setError(`Error connecting to the server: ${error.message || error}`);
              setIsClientConnected(false);
              return; // Arrête l'exécution si la connexion client échoue
            }
          }
      
          // Mettre à jour la session avec les instructions dynamiques
          client.updateSession({ instructions: dynamicInstructions });
      
          // Vérification de l'état de "start"
          if (!hasSentStartRef.current && client.isConnected()) {
            console.log("Log: Sending 'start' message...");
            client.sendUserMessageContent([
              {
                type: 'input_text',
                text: 'start',
              },
            ]);
            hasSentStartRef.current = true;
            console.log("Log: 'Start' message sent and hasSentStartRef updated.");
          }
      
          // Démarrage de l'enregistrement si détection de tour active
          if (client.getTurnDetectionType() === 'server_vad') {
            await wavRecorder.record((data) => client.appendInputAudio(data.mono));
          }
      
        } catch (error: any) {
          Sentry.captureException(error, {
            tags: {
              location: 'ConsolePage - connectConversation - General',
              userAgent: navigator.userAgent,
            }
          });
          console.error("Connection error:", error.message || error);
          setError(`An error occurred during connection: ${error.message || error}`);
        }
      }, [dynamicInstructions, isWavStreamPlayerConnected]);
      
        
      
      
      const disconnectConversation = useCallback(async () => {
        try {
          const client = clientRef.current;
          client.disconnect();
          setIsClientConnected(false);

          const wavRecorder = wavRecorderRef.current;
          const recorderStatus = wavRecorder.getStatus();
          console.log('📝 Recorder status before disconnect:', recorderStatus);

          if (recorderStatus !== 'ended') {
            await wavRecorder.end();
            console.log('✅ Recorder ended successfully');
          }

          const wavStreamPlayer = wavStreamPlayerRef.current;
          await wavStreamPlayer.interrupt();

          hasSentStartRef.current = false;
          setIsRecording(false);
          
        } catch (error: unknown) {
          Sentry.captureException(error, {
            tags: {
              location: 'ConsolePage - disconnectConversation',
              userAgent: navigator.userAgent,
            }
          });
          console.error('❌ Erreur lors de la déconnexion:', error);
          const errorMessage = error instanceof Error ? error.message : 'Unknown error';
          setError(`Error during disconnect: ${errorMessage}`);
          setIsClientConnected(false);
        }
      }, []);


      useEffect(() => {
        console.log('🔄 useEffect fetchTotalMessageCount déclenché', {
          timestamp: new Date().toISOString()
        });
        
        const fetchTotalMessageCount = async () => {
          if (currentUser) {
            try {
              const response = await fetch(`${API_BASE_URL}/api/user/getMessageCount?uid=${currentUser.uid}`);
              const data = await response.json();
              setTotalMessageCount(data.totalMessageCount || 0);
            } catch (error) {
              console.error('Erreur lors de la récupération du total des messages:', error);
            }
          }
        };
      
        fetchTotalMessageCount();
      }, [currentUser, sessionMessageCount]); // Mettre à jour quand sessionMessageCount change
      

      useEffect(() => {
        if (currentUser) {
          console.log('🔄 Début du useEffect de gestion des limites', {
            currentUser: {
              id: currentUser.uid,
              isAnonymous: currentUser.isAnonymous
            }
          });

          // Vérifier si l'utilisateur est dans la liste des utilisateurs spéciaux
          if (SPECIAL_USERS.UNLIMITED_ACCESS.includes(currentUser.uid)) {
            setIsLimitReached(false);
            setError(null);
            return; // Sortir de l'effet si c'est un utilisateur spécial
          }

          // Vérifiez si l'utilisateur est anonyme et a atteint la limite
          if (currentUser.isAnonymous && totalMessageCount >= MESSAGE_LIMITS.ANONYMOUS_USER_PROMPT_LIMIT) {
            setShowSignUpForm(true);
          }

          // Vérifiez si l'utilisateur a atteint la limite gratuite
          if (totalMessageCount >= MESSAGE_LIMITS.FREE_USER_LIMIT) {
            const interpolatedMessage = t('errors.messageLimit', { 
              limit: MESSAGE_LIMITS.FREE_USER_LIMIT 
            });
            
            setIsLimitReached(true);
            setError(interpolatedMessage);
            
            console.log('🔍 Message d\'erreur mis à jour :', {
              interpolatedMessage,
              isLimitReached: true,
              error: interpolatedMessage
            });
          }
        }
      }, [currentUser, totalMessageCount, t]);

      // Utilisation de l'effet pour appeler la fonction au montage
      useEffect(() => {
        console.log("Log: Appel de connectConversation depuis useEffect.");
        connectConversation(); // Connexion automatique au montage
      
        return () => {
          // Nettoyage automatique au démontage
          disconnectConversation();
        };
      }, [connectConversation, disconnectConversation]);
    
    /**
     * Disconnect and reset conversation state
     */


    const recordingStartTimeRef = useRef<number>(0);

    type PointerEvent = React.PointerEvent<HTMLButtonElement>;

    /**
     * En mode appui-pour-parler, démarrer l'enregistrement
     * .appendInputAudio() pour chaque échantillon
     */
    const startRecording = async (event: PointerEvent) => {
      event.preventDefault();
      event.stopPropagation();
      
      if (isLimitReached) {
        console.log('Limite de messages atteinte, enregistrement bloqué');
        return;
      }
      
      const client = clientRef.current;

      // Vérifier si le client est connecté
      if (!client.isConnected()) {
        console.log('Client non connecté. Tentative de connexion...');
        try {
          await client.connect();
          console.log('Client connecté avec succès.');
        } catch (error: any) {
          Sentry.captureException(error, {
            tags: {
              location: 'ConsolePage - startRecording - ClientConnection',
              userAgent: navigator.userAgent,
            }
          });
          console.error('Erreur de connexion au client:', error);
          setError(`Erreur de connexion au serveur: ${error.message || error}`);
          setIsRecording(false);
          return;
        }
      }
      
      try {
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        // Vérifier si la session d'enregistrement est terminée
        const recorderStatus = wavRecorder.getStatus();
        console.log('📝 Recorder status:', recorderStatus);
        
        if (recorderStatus === 'ended') {
          console.log('🔄 Réinitialisation du recorder...');
          await wavRecorder.begin();
        }

        // Ne continuer que si pas déjà en train d'enregistrer
        if (wavRecorder.getStatus() === 'recording') {
          console.log('⚠️ Déjà en train d\'enregistrer');
          return;
        }

        setIsRecording(true);
        recordingStartTimeRef.current = Date.now();
        
        // Interrompre la lecture audio en cours
        const trackSampleOffset = await wavStreamPlayer.interrupt();
        if (trackSampleOffset?.trackId) {
          const { trackId, offset } = trackSampleOffset;
          await client.cancelResponse(trackId, offset);
        }

        // Démarrer l'enregistrement
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
        console.log('✅ Enregistrement démarré');
        
      } catch (error: unknown) {
        Sentry.captureException(error, {
          tags: {
            location: 'ConsolePage - startRecording',
            userAgent: navigator.userAgent,
          }
        });
        console.error('❌ Erreur lors du démarrage de l\'enregistrement:', error);
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        setError(`Error starting recording: ${errorMessage}`);
        setIsRecording(false);
      }
    };

    const stopRecording = async (event: PointerEvent) => {
      event.preventDefault();
      event.stopPropagation();
      
      if (isLimitReached) {
        console.log('Limite de messages atteinte, enregistrement bloqué');
        return;
      }
      
      try {
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;

        const recordingDuration = Date.now() - recordingStartTimeRef.current;
        const MIN_RECORDING_DURATION = 1000;

        if (recordingDuration < MIN_RECORDING_DURATION) {
          console.log('⚠️ Enregistrement trop court, annulation');
          setIsRecording(false);
          await wavRecorder.pause();
          return;
        }

        const recorderStatus = wavRecorder.getStatus();
        if (recorderStatus === 'ended') {
          console.log('⚠️ Session terminée, réinitialisation nécessaire');
          await wavRecorder.begin();
        }

        setIsRecording(false);
        await wavRecorder.pause();
        client.createResponse();
        console.log('✅ Enregistrement arrêté et réponse créée');
        
      } catch (error: any) {
        Sentry.captureException(error, {
          tags: {
            location: 'ConsolePage - stopRecording',
            userAgent: navigator.userAgent,
          }
        });
        console.error('❌ Erreur lors de l\'arrêt de l\'enregistrement:', error);
        setError(`Error stopping recording: ${error.message || error}`);
        setIsRecording(false);
      }
    };

    const handleTranslate = async (messageId: string) => {
      try {
        const index = items.findIndex(item => item.id === messageId);

        if (index === -1) {
          console.log("Message non trouvé pour l'ID :", messageId);
          return;
        }

        const messageToTranslate = items[index];
        const textToTranslate =
          messageToTranslate.formatted.text ||
          messageToTranslate.formatted.transcript;

        if (!textToTranslate) {
          console.log('Aucun texte à traduire.');
          return;
        }

        const response = await fetch(`${API_BASE_URL}/translate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: textToTranslate,
            targetLanguage: nativeLanguage,
          }),
        });

        const data = await response.json();

        if (data && data.translatedText) {
          console.log('Texte traduit :', data.translatedText);

          const updatedItem: ConversationItemType = {
            ...messageToTranslate,
            translation: data.translatedText,
          };

          const newItems = [...items];
          newItems[index] = updatedItem;

          setItems(newItems);
        } else {
          throw new Error(data.error || 'Erreur inconnue lors de la traduction');
        }
      } catch (error: any) {
        Sentry.captureException(error, {
          tags: {
            location: 'ConsolePage - handleTranslate',
            userAgent: navigator.userAgent,
            messageId,
            targetLanguage: nativeLanguage,
          },
          extra: {
            currentItemsCount: items.length,
          }
        });
        
        console.error('Erreur lors de la traduction:', error);
        setError(`Erreur de traduction: ${error.message || 'Erreur inconnue'}`);
      }
    };



      

    const conversationContentRef = useRef<HTMLDivElement>(null);

    /**
     * Auto-scroll the conversation logs
     */
    useEffect(() => {
      if (conversationContentRef.current) {
        conversationContentRef.current.scrollTop =
          conversationContentRef.current.scrollHeight;
      }
    }, [items]);

      /**
       * Set up render loops for the visualization canvas
       */
      useEffect(() => {
        let isLoaded = true;

        const wavRecorder = wavRecorderRef.current;
        const clientCanvas = clientCanvasRef.current;
        let clientCtx: CanvasRenderingContext2D | null = null;

        const wavStreamPlayer = wavStreamPlayerRef.current;
        const serverCanvas = serverCanvasRef.current;
        let serverCtx: CanvasRenderingContext2D | null = null;

        const render = () => {
          if (isLoaded) {
            if (clientCanvas) {
              if (!clientCanvas.width || !clientCanvas.height) {
                clientCanvas.width = clientCanvas.offsetWidth;
                clientCanvas.height = clientCanvas.offsetHeight;
              }
              clientCtx = clientCtx || clientCanvas.getContext('2d');
              if (clientCtx) {
                clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
                const result = wavRecorder.recording
                  ? wavRecorder.getFrequencies('voice')
                  : { values: new Float32Array([0]) };
                WavRenderer.drawBars(
                  clientCanvas,
                  clientCtx,
                  result.values,
                  '#0099ff',
                  10,
                  0,
                  8
                );
              }
            }
            if (serverCanvas) {
              if (!serverCanvas.width || !serverCanvas.height) {
                serverCanvas.width = serverCanvas.offsetWidth;
                serverCanvas.height = serverCanvas.offsetHeight;
              }
              serverCtx = serverCtx || serverCanvas.getContext('2d');
              if (serverCtx) {
                serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
                const result = wavStreamPlayer.analyser
                  ? wavStreamPlayer.getFrequencies('voice')
                  : { values: new Float32Array([0]) };
                WavRenderer.drawBars(
                  serverCanvas,
                  serverCtx,
                  result.values,
                  '#009900',
                  10,
                  0,
                  8
                );
              }
            }
            window.requestAnimationFrame(render);
          }
        };
        render();

        return () => {
          isLoaded = false;
        };
      }, []);

      /**
       * Core RealtimeClient and audio capture setup
       * Set all of our instructions, tools, events and more
       */
      
      /* Commentaire du son
      useEffect(() => {
        completionSound.current.load();
      }, []);
      */

      
      useEffect(() => {
        const wavStreamPlayer = wavStreamPlayerRef.current;
        const client = clientRef.current;

        client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

        client.on('error', (event: any) => console.error(event));
        
        client.on('conversation.interrupted', async () => {
          const trackSampleOffset = await wavStreamPlayer.interrupt();
          if (trackSampleOffset?.trackId) {
            const { trackId, offset } = trackSampleOffset;
            await client.cancelResponse(trackId, offset);
          }
        });

        client.on('conversation.updated', async ({ item, delta }: any) => {
          // Update the items state with the new item data
          setItems((prevItems) => {
            const itemIndex = prevItems.findIndex((i) => i.id === item.id);
            let newItems;
        
            if (itemIndex !== -1) {
              // Update existing item
              newItems = [...prevItems];
              newItems[itemIndex] = { ...item };
            } else {
              // Add new item
              newItems = [...prevItems, item];
            }
        
            return newItems;
          });
        
          // Handle audio deltas
          if (delta?.audio) {
            wavStreamPlayer.add16BitPCM(delta.audio, item.id);
          }
        
          // When item is completed, handle message counts and storage
          if (
            item.status === 'completed' &&
            (item.formatted.text || item.formatted.transcript)
          ) {
            const messageContent = item.formatted.text || item.formatted.transcript;
        
            if (messageContent && messageContent.trim() !== '') {
              // Increment message count
              if (item.role === 'user' && messageContent.toLowerCase() !== 'start') {
                setSessionMessageCount((prev) => {
                  const newCount = prev + 1;
                  return newCount;
                });
              }
        
              // Save message to database if needed
              if (currentUser) {
                try {
                  await fetch(`${API_BASE_URL}/api/conversation/message`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      uid: currentUser.uid,
                      studyLanguage,
                      scenarioId: selectedScenario,
                      message: messageContent,
                      role: item.role,
                      timestamp: new Date().toISOString(),
                    }),
                  });
                } catch (error) {
                  console.warn('Failed to save message to database:', error);
                }
              }
            }
        
            // Decode audio if available
            if (item.formatted.audio?.length) {
              try {
                const wavFile = await WavRecorder.decode(
                  item.formatted.audio,
                  24000,
                  24000
                );
                item.formatted.file = wavFile;
              } catch (error) {
                console.warn('Audio decode warning:', error);
              }
            }
          }
        });
        

        return () => {
          client.reset();
        };
      }, [currentUser, studyLanguage, selectedScenario]); // Ajout des dépendances nécessaires


      /**
       * Render the application
       */
      return (
        <div 
          data-component="ConsolePage" 
          className="min-h-screen w-screen flex flex-col overflow-hidden m-0 font-sans font-normal text-base"
        >
          <div className="absolute top-4 right-4 flex flex-col items-end gap-2">
            <div className="bg-blue-50 border border-blue-200 shadow-sm px-3 py-1 rounded-full">
              ⭐️ {totalMessageCount}/20
            </div>
          </div>
          <div className="flex items-center justify-center p-2 px-4 min-h-[40px]">
            <div className="flex items-center gap-3">
              <img src="/openai-logomark.svg" alt="Logo" className="w-6 h-6" />
            </div>
          </div>
      
          <div className="flex-1 flex flex-col overflow-hidden">
            {showSignUpForm ? (
              <div className="flex items-center justify-center h-full">
                <SignUpForm onSuccess={handleSignUpSuccess} />
              </div>
            ) : (
              <>
                {showSuccessMessage && (
                  <div className="fixed top-16 w-full text-center p-4 bg-green-100 text-green-800 font-semibold z-50 animate-fade-in-down shadow-lg">
                    {t('signUpForm.successMessage')}
                  </div>
                )}
                
                {isLimitReached && (
                  <div className="fixed top-16 w-full text-center p-4 bg-red-100 text-red-800 font-semibold z-50 animate-fade-in-down shadow-lg">
                    {t('errors.messageLimit', { limit: MESSAGE_LIMITS.FREE_USER_LIMIT })}
                  </div>
                )}
                
                <div
                  className="flex-grow flex flex-col w-full overflow-y-auto pb-4 max-h-[calc(100vh-150px)] md:max-h-[calc(100vh-200px)] lg:max-h-[calc(100vh-300px)]"
                  ref={conversationContentRef}
                >
                  <div className="content-block-body" data-conversation-content>
                    {error ? (
                      <div className="text-red-600 font-bold m-5 text-center">
                        {error}
                      </div>
                    ) : !items.length ? (
                      <div className="flex items-center justify-center h-full mt-20">
                        <div className="w-12 h-12 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      items
                        .filter((item, index) => {
                          if (
                            index === 0 &&
                            item.role === 'user' &&
                            item.formatted.text?.trim().toLowerCase() === 'start'
                          ) {
                            return false;
                          }
                          return true;
                        })
                        .map((conversationItem) => (
                          <div
                            key={conversationItem.id}
                            className={`flex ${
                              conversationItem.role === 'assistant' ? 'justify-start' : 'justify-end'
                            } mb-4 mx-4 lg:container lg:mx-auto lg:px-52 xl:px-96`}
                          >
                            <div
                              className={`relative p-4 rounded-2xl ${
                                conversationItem.role === 'assistant' 
                                  ? 'bg-white border border-gray-200 shadow-sm max-w-[80%] md:max-w-[85%] lg:max-w-[90%]' 
                                  : 'bg-blue-50 border border-blue-200 shadow-sm max-w-[80%] md:max-w-[85%] lg:max-w-[90%]'
                              }`}
                            >
                              <div className="text-gray-800 text-base md:text-lg pr-4">
                                {/* Messages de l'assistant */}
                                {!conversationItem.formatted.tool && conversationItem.role === 'assistant' && (
                                  <div>
                                    {preventLineBreaks(
                                      conversationItem.formatted.transcript ||
                                      conversationItem.formatted.text ||
                                      (conversationItem.status === 'in_progress' ? '...' : '(truncated)')
                                    )}
                                    {/* Indicateur de frappe pendant la génération */}
                                    {conversationItem.status === 'in_progress' && (
                                      <span className="inline-block ml-1 animate-pulse">▋</span>
                                    )}
                                    {/* Traduction si disponible */}
                                    {conversationItem.translation && (
                                      <div className="mt-2 text-sm text-gray-500 italic">
                                        {conversationItem.translation}
                                      </div>
                                    )}
                                  </div>
                                )}

                                {/* Messages de l'utilisateur */}
                                {!conversationItem.formatted.tool && conversationItem.role === 'user' && (
                                  <div>
                                    {preventLineBreaks(
                                      conversationItem.formatted.transcript ||
                                      (conversationItem.formatted.audio?.length
                                        ? '(transcription en cours...)'
                                        : conversationItem.formatted.text || '(message envoyé)')
                                    )}
                                  </div>
                                )}

                                {/* Affichage des appels de fonction */}
                                {conversationItem.type === 'function_call_output' && (
                                  <div>{preventLineBreaks(conversationItem.formatted.output)}</div>
                                )}
                                
                                {/* Affichage des outils */}
                                {!!conversationItem.formatted.tool && (
                                  <div>
                                    {preventLineBreaks(conversationItem.formatted.tool.name)}
                                    ({conversationItem.formatted.tool.arguments})
                                  </div>
                                )}
                              </div>

                              {/* Bouton de traduction pour les messages de l'assistant */}
                              {conversationItem.role === 'assistant' && (
                                <button
                                  onClick={() => handleTranslate(conversationItem.id)}
                                  className="absolute bottom-2 right-2 opacity-50 hover:opacity-100 transition-opacity"
                                  aria-label="Translate"
                                >
                                  <img
                                    src="/translation-button.png"
                                    alt="Translate"
                                    className="w-5 h-5"
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
  
                <div className="flex justify-center p-1 rounded-lg gap-0.5 w-full mt-auto mb-4">
                  <div className="flex items-center h-10 w-24 gap-1">
                    <canvas ref={clientCanvasRef} className="w-full h-full text-current" />
                  </div>
                  <div className="flex items-center h-10 w-24 gap-1">
                    <canvas ref={serverCanvasRef} className="w-full h-full text-current" />
                  </div>
                </div>
  
                <div className="flex justify-center gap-4 pb-4 sm:pb-2">
                  <div className="spacer" />
                  {canPushToTalk && (
                    <button
                    className={`select-none border-0 rounded-full p-3 cursor-pointer inline-flex items-center justify-center w-20 h-20 ${
                      isRecording ? 'bg-red-600' : 'bg-blue-500'
                    } disabled:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed sm:w-[60px] sm:h-[60px]`}
                    style={{ 
                      touchAction: 'none',
                      pointerEvents: isLimitReached || !isClientConnected ? 'none' : 'auto'
                    }}
                    disabled={!canPushToTalk || showSignUpForm || isLimitReached || !isClientConnected}
                    onPointerDown={startRecording}
                    onPointerUp={stopRecording}
                    onContextMenu={(e) => e.preventDefault()}
                    aria-label="Microphone"
                  >
                    <Mic
                      className={`text-white ${isRecording ? '' : ''} w-9 h-9 sm:w-6 sm:h-6 ${
                        isLimitReached || !isClientConnected ? 'opacity-50' : ''
                      }`}
                    />
                  </button>
                  )}
                  <div className="spacer" />
                </div>
              </>
            )}
          </div>
        </div>
      );
      
      
      
    }
