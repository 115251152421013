// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ar from './locales/ar/translation.json';
import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import hi from './locales/hi/translation.json';
import it from './locales/it/translation.json';
import ja from './locales/ja/translation.json';
import ko from './locales/ko/translation.json';
import nl from './locales/nl/translation.json';
import pl from './locales/pl/translation.json';
import pt from './locales/pt/translation.json';
import ru from './locales/ru/translation.json';
import tr from './locales/tr/translation.json';
import zh from './locales/zh/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
        ar: { translation: ar },
        de: { translation: de },
        en: { translation: en },
        es: { translation: es },
        fr: { translation: fr },
        hi: { translation: hi },
        it: { translation: it },
        ja: { translation: ja },
        ko: { translation: ko },
        nl: { translation: nl },
        pl: { translation: pl },
        pt: { translation: pt },
        ru: { translation: ru },
        tr: { translation: tr },
        zh: { translation: zh },
      },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'htmlTag', 'localStorage', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage'],
    },
  });

export default i18n;