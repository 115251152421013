// src/components/Layout.tsx
import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen w-screen p-4 sm:p-6 md:p-10 lg:pt-72 sm:pt-16 pt-4 flex justify-center overflow-x-hidden">
      <div className="w-full max-w-screen-lg lg:max-w-full flex flex-col items-center">
        {children}
      </div>
    </div>
  );
};