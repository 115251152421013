// src/config/environment.ts

const environment = {
  production: {
    API_URL: 'https://www.voxanet.com',
  },
  staging: {
    API_URL: 'https://voxanet-staging-493b6aeff4ec.herokuapp.com',
  },
  development: {
    API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8082',
  }
};

export const getApiUrl = () => {
  // Local development
  if (window.location.hostname === 'localhost') {
    return environment.development.API_URL;
  }
  
  // Staging environment
  if (window.location.hostname.includes('staging')) {
    return environment.staging.API_URL;
  }
  
  // Production by default
  return environment.production.API_URL;
};