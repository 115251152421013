// src/App.tsx

import React, { useState, useEffect } from 'react';
import { ConsolePage } from './pages/ConsolePage';
import { LandingPage } from './components/LandingPage';
import { StudyLanguageSelection } from './components/StudyLanguageSelection';
import { StudyLevelSelection } from './components/StudyLevelSelection';
import { ConversationScenarioSelection } from './components/ConversationScenarioSelection';
import { AuthProvider } from './context/AuthContext';
import i18n from './i18n';
import './App.scss';
import { getApiUrl } from './config/environment';
import * as Sentry from "@sentry/react";
import { logger } from './utils/logger';

function App() {
  const [isStarted, setIsStarted] = useState(false);
  const [nativeLanguage, setNativeLanguage] = useState<string>(i18n.language);
  const [studyLanguage, setStudyLanguage] = useState<string>('');
  const [studyLevel, setStudyLevel] = useState<number>(0);
  const [selectedScenario, setSelectedScenario] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<'landing' | 'language' | 'level' | 'scenario'>('landing');
  const [hasInitialized, setHasInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.documentElement.lang = nativeLanguage;

    const updateMetaTag = (name: string, content: string) => {
      let element = document.querySelector(`meta[name="${name}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('name', name);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    };

    const updateOgTag = (property: string, content: string) => {
      let element = document.querySelector(`meta[property="${property}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute('property', property);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    };

    document.title = i18n.t('seo.metaTitle');
    updateMetaTag('description', i18n.t('seo.metaDescription'));
    updateOgTag('og:title', i18n.t('seo.ogTitle'));
    updateOgTag('og:description', i18n.t('seo.ogDescription'));
    updateOgTag('og:type', i18n.t('seo.ogType'));
    updateOgTag('og:url', i18n.t('seo.ogUrl'));
    updateOgTag('og:image', i18n.t('seo.ogImage'));

  }, [nativeLanguage]);

  useEffect(() => {
    const baseUrl = getApiUrl();
    const languages = ['en', 'fr', 'es', 'de', 'it', 'ja', 'ko', 'pt', 'zh', 'ru', 'ar', 'hi', 'nl', 'pl', 'tr'];

    document.querySelectorAll('link[rel="alternate"]').forEach(link => link.remove());

    languages.forEach((lang) => {
      const link = document.createElement('link');
      link.rel = 'alternate';
      link.hreflang = lang;
      link.href = `${baseUrl}/${lang}`;
      document.head.appendChild(link);
    });

    const defaultLink = document.createElement('link');
    defaultLink.rel = 'alternate';
    defaultLink.hreflang = 'x-default';
    defaultLink.href = baseUrl;
    document.head.appendChild(defaultLink);
  }, []);

  useEffect(() => {
    if (currentStep === 'landing' && !hasInitialized) {
      setHasInitialized(true);
    }
  }, [currentStep, hasInitialized]);

  const handleGetStarted = () => setCurrentStep('language');

  const handleStudyLanguageSelect = (language: string) => {
    logger.log("Langue d'étude sélectionnée dans App.tsx :", language);
    setStudyLanguage(language);
    setCurrentStep('level');
  };

  const handleStudyLevelSelect = (level: number) => {
    setStudyLevel(level);
    setCurrentStep('scenario');
  };

  const handleScenarioSelect = (scenario: string) => {
    setSelectedScenario(scenario);
    setIsStarted(true);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'landing':
        return (
          <>
            {logger.log("LandingPage rendu")}
            <LandingPage
              onGetStarted={handleGetStarted}
              selectedLanguage={nativeLanguage}
              onLanguageChange={(lang) => {
                setNativeLanguage(lang);
                i18n.changeLanguage(lang);
              }}
            />
          </>
        );
      case 'language':
        return (
          <StudyLanguageSelection
            onLanguageSelect={handleStudyLanguageSelect}
            studyLanguage={studyLanguage}
          />
        );
      case 'level':
        return (
          <StudyLevelSelection
            onLevelSelect={handleStudyLevelSelect}
            selectedLevel={studyLevel}
          />
        );
      case 'scenario':
        return (
          <ConversationScenarioSelection
            onScenarioSelect={handleScenarioSelect}
            selectedScenario={selectedScenario}
            studyLanguage={studyLanguage}
          />
        );
      default:
        return (
          <ConsolePage
            nativeLanguage={nativeLanguage}
            studyLanguage={studyLanguage}
            studyLevel={studyLevel}
            selectedScenario={selectedScenario}
          />
        );
    }
  };

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const userLang = navigator.language.split('-')[0] || 'en';
        setNativeLanguage(userLang);
        await i18n.changeLanguage(userLang);
      } catch (error) {
        logger.error(error as Error, {
          location: 'App initialization',
          userLanguage: navigator.language
        });
      } finally {
        setIsLoading(false);
      }
    };

    initializeApp();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <AuthProvider>
      <div 
        className="fixed top-0 left-0 w-full h-full"
        style={{
          backgroundImage: 'url("/background-for-all-website.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }}
      />
      <div className="relative min-h-screen flex flex-col items-center justify-center">
        {!isStarted ? renderStep() : (
          <ConsolePage
            nativeLanguage={nativeLanguage}
            studyLanguage={studyLanguage}
            studyLevel={studyLevel}
            selectedScenario={selectedScenario}
          />
        )}
      </div>
    </AuthProvider>
  );
}

export default App;
