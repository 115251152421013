import * as Sentry from "@sentry/react";

const isDev = process.env.NODE_ENV === 'development';

// Définir le type pour le contexte
type SentryContext = {
  [key: string]: any;
};

export const logger = {
  log: (...args: any[]) => {
    if (isDev) {
      console.log(...args);
    }
  },
  
  error: (error: Error, context?: SentryContext) => {
    if (isDev) {
      console.error('[Error]', error, context);
    } else {
      Sentry.captureException(error, {
        extra: context as Record<string, any>
      });
    }
  },
  
  warn: (...args: any[]) => {
    if (isDev) {
      console.warn(...args);
    }
  },
  
  info: (...args: any[]) => {
    if (isDev) {
      console.info(...args);
    }
  }
};