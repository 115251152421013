// SignUpForm.tsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import i18n translation hook
import { auth } from '../firebaseConfig';
import { 
  createUserWithEmailAndPassword, 
  EmailAuthProvider,
  linkWithCredential,
  AuthErrorCodes
} from 'firebase/auth';
import { getApiUrl } from '../config/environment';

interface SignUpFormProps {
  onSuccess: () => void;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ onSuccess }) => {
  const { t } = useTranslation(); // Use translation hook
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("🚀 Début du processus d'inscription");
    
    try {
      const anonymousUser = auth.currentUser;
      console.log("👤 Utilisateur actuel :", {
        exists: !!anonymousUser,
        uid: anonymousUser?.uid,
        isAnonymous: anonymousUser?.isAnonymous,
        email: anonymousUser?.email
      });
      
      if (!anonymousUser) {
        console.error("❌ Aucun utilisateur anonyme trouvé");
        return;
      }

      // Créer un credential avec l'email et le mot de passe
      console.log("📧 Tentative de création des credentials pour:", email);
      const credential = EmailAuthProvider.credential(email, password);

      try {
        console.log("🔄 Tentative de liaison du compte anonyme avec email");
        const userCredential = await linkWithCredential(anonymousUser, credential);
        console.log("✅ Compte mis à niveau avec succès:", {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          isAnonymous: userCredential.user.isAnonymous
        });

        // Mettre à jour le statut du compte dans la base de données
        const apiUrl = getApiUrl();
        console.log("🔄 Mise à jour du statut dans la base de données:", apiUrl);
        
        const response = await fetch(`${apiUrl}/api/user/updateEmailStatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uid: userCredential.user.uid,
            email: email,
            isAnonymous: false
          })
        });

        const data = await response.json();
        console.log("✅ Réponse de l'API:", data);

        // Appeler onSuccess après une inscription réussie
        onSuccess();

      } catch (error: any) {
        console.error("❌ Erreur détaillée:", {
          code: error.code,
          message: error.message,
          fullError: error
        });

        if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
          const apiUrl = getApiUrl();
          await fetch(`${apiUrl}/api/user/updateEmailStatus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              uid: anonymousUser.uid,
              email: email,
              isAnonymous: false
            })
          });
          onSuccess();
        } else {
          console.error("❌ Erreur lors de la mise à niveau du compte:", error);
          setError(error.message);
        }
      }
    } catch (error: any) {
      console.error("❌ Erreur générale lors de l'inscription:", {
        error: error.message,
        fullError: error
      });
      setError(error.message);
    }
  };

  return (
    <div className="text-center">
      {/* Message i18n pour encourager l'inscription */}
      <p className="mb-2 text-gray-700 font-semibold">{t('signUpForm.saveProgressMessage')}</p>
      <p className="mb-4 text-gray-500">{t('signUpForm.saveProgressDescription')}</p>

      {error && <p className="mb-4 text-red-500">{error}</p>}
      <form onSubmit={handleSignUp} className="space-y-4">
        <input
          type="email"
          placeholder={t('signUpForm.emailPlaceholder')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <input
          type="password"
          placeholder={t('signUpForm.choosePassword')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          type="submit"
          className="w-full py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-200"
        >
          {t('signUpForm.signUpButton')}
        </button>
      </form>
    </div>
  );
};

export default SignUpForm;
