// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCGwnFcn5o_9GXjjb75D8rszhxfv_j01qM",
  authDomain: "www.voxanet.com",
  projectId: "nouveau-voxanet-2a5e7",
  storageBucket: "nouveau-voxanet-2a5e7.appspot.com",
  messagingSenderId: "722747874132",
  appId: "1:722747874132:web:aaf9d82a49a409bdebc6c1",
  measurementId: "G-Y7SE2YDVRH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Export app if you need it for other Firebase services
export default app;
