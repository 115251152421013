// src/components/ConversationScenarioSelection.tsx

import React from 'react';
import { Layout } from './Layout';
import { useTranslation } from 'react-i18next';
import './ConversationScenarioSelection.scss';
import { useAuth } from '../context/AuthContext';

interface ScenarioSelectionProps {
  onScenarioSelect: (scenarioId: string) => void;
  selectedScenario: string;
  studyLanguage: string;
}

// Liste de scénarios avec des clés de traduction
const scenarios = [
  { id: 'cafe', titleKey: 'conversationScenarioSelection.scenarios.cafe.title', descriptionKey: 'conversationScenarioSelection.scenarios.cafe.description' },
  { id: 'airport', titleKey: 'conversationScenarioSelection.scenarios.airport.title', descriptionKey: 'conversationScenarioSelection.scenarios.airport.description' },
  { id: 'restaurant', titleKey: 'conversationScenarioSelection.scenarios.restaurant.title', descriptionKey: 'conversationScenarioSelection.scenarios.restaurant.description' },
  { id: 'hotel', titleKey: 'conversationScenarioSelection.scenarios.hotel.title', descriptionKey: 'conversationScenarioSelection.scenarios.hotel.description' },
  { id: 'shopping', titleKey: 'conversationScenarioSelection.scenarios.shopping.title', descriptionKey: 'conversationScenarioSelection.scenarios.shopping.description' },
  { id: 'doctor', titleKey: 'conversationScenarioSelection.scenarios.doctor.title', descriptionKey: 'conversationScenarioSelection.scenarios.doctor.description' },
  { id: 'free_chat', titleKey: 'conversationScenarioSelection.scenarios.freeChat.title', descriptionKey: 'conversationScenarioSelection.scenarios.freeChat.description' }
];

export const ConversationScenarioSelection: React.FC<ScenarioSelectionProps> = ({
  onScenarioSelect,
  selectedScenario,
  studyLanguage
}) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const handleScenarioSelect = async (scenarioId: string) => {
    if (!currentUser) {
      console.error("Pas d'utilisateur connecté");
      return;
    }

    try {
      const response = await fetch('/api/user/updateScenarios', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: currentUser.uid,
          studyLanguage,
          scenarioId,
          lastAccessed: new Date()
        })
      });

      const data = await response.json();
      console.log("Scénario enregistré:", data);
      onScenarioSelect(scenarioId);
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du scénario:", error);
    }
  };

  return (
    <Layout>
      <div className="conversation-scenario-selection custom-top-padding">
        <h2>{t('conversationScenarioSelection.title')}&nbsp;:</h2>
        <div className="scenarios-list">
          {scenarios.map((scenario) => (
            <div
              key={scenario.id}
              className={`scenario-card ${selectedScenario === scenario.id ? 'selected' : ''}`}
              onClick={() => handleScenarioSelect(scenario.id)}
            >
              <h3>{t(scenario.titleKey)}</h3>
              <p>{t(scenario.descriptionKey)}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
