// src/components/LandingPage.tsx

import React, { useEffect, useState } from 'react';
import { Layout } from './Layout';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { auth } from '../firebaseConfig';
import { signInAnonymously, onAuthStateChanged, User } from 'firebase/auth';
import { getApiUrl } from '../config/environment';

interface LandingPageProps {
  onGetStarted: () => void;
  selectedLanguage: string;
  onLanguageChange: (language: string) => void;
}

const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsch' },
  { code: 'zh', name: '中文' },
  { code: 'ar', name: 'العربية' },
  { code: 'ru', name: 'Русский' },
  { code: 'pt', name: 'Português' },
  { code: 'ja', name: '日本語' },
  { code: 'it', name: 'Italiano' },
  { code: 'ko', name: '한국어' },
  { code: 'hi', name: 'हिन्दी' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'pl', name: 'Polski' },
  { code: 'nl', name: 'Nederlands' }
];

export const LandingPage: React.FC<LandingPageProps> = ({
  onGetStarted,
  selectedLanguage,
  onLanguageChange,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [defaultBrowserLanguage, setDefaultBrowserLanguage] = useState('');
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const detectedLanguage = navigator.language.split('-')[0] || 'en';
    setDefaultBrowserLanguage(detectedLanguage);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isInitialized && defaultBrowserLanguage) {
      let mounted = true;
      let unsubscribe: (() => void) | null = null;
      
      const initializeUser = async () => {
        try {
          const currentAuthUser = auth.currentUser;
          if (currentAuthUser) {
            console.log("Utilisateur existant trouvé:", currentAuthUser.uid);
            setCurrentUser(currentAuthUser);
            await registerOrUpdateUser(currentAuthUser.uid, selectedLanguage);
            return;
          }

          const user = await new Promise<User | null>((resolve) => {
            unsubscribe = onAuthStateChanged(auth, (user) => {
              if (mounted) {
                resolve(user);
                if (unsubscribe) unsubscribe();
              }
            });
          });
          
          if (!mounted) return;

          if (user) {
            console.log(`Utilisateur ${user.isAnonymous ? 'anonyme' : 'connecté'} identifié:`, user.uid);
            setCurrentUser(user);
            await registerOrUpdateUser(user.uid, selectedLanguage);
          } else {
            try {
              const { user: newUser } = await signInAnonymously(auth);
              if (!mounted) return;
              
              console.log("Nouvel utilisateur anonyme créé :", newUser.uid);
              setCurrentUser(newUser);
              await registerOrUpdateUser(newUser.uid, selectedLanguage);
            } catch (error) {
              console.error("Erreur lors de la création de l'utilisateur anonyme:", error);
            }
          }
        } catch (error) {
          console.error("Erreur d'initialisation:", error);
        } finally {
          if (mounted) {
            setIsInitialized(true);
          }
        }
      };

      initializeUser();

      return () => {
        mounted = false;
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [isInitialized, defaultBrowserLanguage]);

  

  const handleLanguageChange = (language: string) => {
    console.log("Langue maternelle sélectionnée :", language);
    onLanguageChange(language);
    i18n.changeLanguage(language);
  };

  const handleGetStartedClick = () => {
    console.log("handleGetStartedClick déclenché");
  
    // Mise à jour en arrière-plan sans bloquer la navigation
    if (currentUser) {
      console.log("Mise à jour de l'utilisateur en arrière-plan avec la langue maternelle :", selectedLanguage);
      registerOrUpdateUser(currentUser.uid, selectedLanguage)
        .catch(error => console.error("Erreur lors de l'enregistrement en arrière-plan:", error));
    }
  
    // Navigation immédiate
    console.log("Clic sur 'Get Started' détecté");
    onGetStarted(); // Passage instantané à StudyLanguageSelection
  };
  

  async function registerOrUpdateUser(uid: string, nativeLanguageSelected: string) {
    console.log("Début de registerOrUpdateUser"); // Log au début de la fonction
    
    if (!uid || !nativeLanguageSelected) {
      console.warn("UID ou langue native manquants, annulation de l'enregistrement"); 
      return;
    }
  
    try {
      const apiUrl = getApiUrl();
      console.log("API URL utilisée :", apiUrl);
  
      const startRequestTime = performance.now();
      const response = await fetch(`${apiUrl}/api/user/registerOrUpdate`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ 
          uid, 
          defaultBrowserLanguage,
          nativeLanguageSelected 
        })
      });
      const endRequestTime = performance.now();
      console.log(`Requête d'enregistrement terminée en ${endRequestTime - startRequestTime} ms`);
  
      if (!response.ok) {
        throw new Error("Erreur lors de l'enregistrement");
      }
    } catch (error) {
      console.error("Erreur MongoDB:", error);
    } finally {
      console.log("Fin de registerOrUpdateUser"); // Log à la fin de la fonction
    }
  }
  

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="relative z-10 flex flex-col items-center justify-start py-9">
        <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-10 text-center mx-auto lg:max-w-full max-w-md">
          {t('landingPage.title')}
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl text-gray-800 mb-10 text-center mx-auto lg:max-w-full max-w-md">
          {t('landingPage.description')}
        </p>
        
        <div className="text-center mb-12">
          <label
            htmlFor="language-select"
            className="text-lg md:text-xl lg:text-2xl font-bold text-gray-700 mb-4 block text-center"
          >
            {t('landingPage.selectNativeLanguage')}
          </label>
          <select
            id="language-select"
            value={selectedLanguage}
            onChange={(e) => handleLanguageChange(e.target.value)}
            className="p-3 border border-gray-300 rounded-lg text-lg md:text-xl cursor-pointer w-auto max-w-fit shadow-sm focus:ring-2 focus:ring-blue-300"
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>

        <button
          id="getStartedButton"
          onClick={handleGetStartedClick}
          className="px-8 py-4 bg-blue-600 text-white font-semibold text-lg md:text-xl rounded-lg hover:bg-blue-700 shadow-lg transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-blue-300 w-11/12 md:w-auto uppercase tracking-wide"
        >
          {t('landingPage.getStartedButton')}
        </button>
      </div>
    </Layout>
  );
};

export default LandingPage;
