// src/components/StudyLanguageSelection.tsx

import React, { useState } from 'react';
import { Layout } from './Layout';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';

interface StudyLanguageSelectionProps {
  onLanguageSelect: (language: string) => void;
  studyLanguage: string;
}

const studyLanguages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsch' },
  { code: 'zh', name: '中文' },
  { code: 'ar', name: 'العربية' },
  { code: 'ru', name: 'Русский' },
  { code: 'pt', name: 'Português' },
  { code: 'ja', name: '日本語' },
  { code: 'it', name: 'Italiano' },
  { code: 'ko', name: '한국어' },
  { code: 'hi', name: 'हिन्दी' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'pl', name: 'Polski' },
  { code: 'nl', name: 'Nederlands' }
];

export const StudyLanguageSelection: React.FC<StudyLanguageSelectionProps> = ({
  onLanguageSelect,
  studyLanguage,
}) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(studyLanguage || 'en');

  const handleLanguageSelect = (language: string) => {
    console.log("Langue d'étude sélectionnée :", language);
    setSelectedLanguage(language);
  };

  const handleConfirmClick = () => {
    if (!currentUser) {
      console.error("Pas d'utilisateur connecté");
      return;
    }
  
    const languageToConfirm = selectedLanguage || studyLanguage;
    console.log("Confirmation de la langue d'étude :", languageToConfirm);
  
    // Naviguer immédiatement
    onLanguageSelect(languageToConfirm);
  
    // Ensuite, exécuter la mise à jour en arrière-plan
    fetch('/api/user/registerOrUpdate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        uid: currentUser.uid,
        studyLanguage: languageToConfirm
      })
    })
    .then(response => response.json())
    .then(data => console.log("Langue d'étude enregistrée:", data))
    .catch(error => console.error("Erreur:", error));
  };
  

  return (
    <Layout>
      <div className="relative z-10 flex flex-col items-center justify-start py-9">
        <h1 className="text-4xl md:text-5xl text-gray-900 mb-10 text-center mx-auto lg:max-w-full max-w-md">
          {t('studyLanguageSelection.title')}&nbsp;:
        </h1>
        
        <div className="text-center mb-12">
          <select
            id="study-language-select"
            value={selectedLanguage}
            onChange={(e) => handleLanguageSelect(e.target.value)}
            className="p-3 border border-gray-300 rounded-lg text-lg md:text-xl cursor-pointer w-auto max-w-fit shadow-sm focus:ring-2 focus:ring-blue-300"
          >
            {studyLanguages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleConfirmClick}
          className="px-8 py-4 bg-blue-600 text-white font-semibold text-lg md:text-xl rounded-lg hover:bg-blue-700 shadow-lg transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-blue-300 w-11/12 md:w-auto uppercase tracking-wide"
        >
          {t('studyLanguageSelection.confirmButton')}
        </button>
      </div>
    </Layout>
  );
};

export default StudyLanguageSelection;
