// src/components/StudyLevelSelection.tsx

import React from 'react';
import { Layout } from './Layout';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import './StudyLevelSelection.scss'; // Importez le fichier SCSS

interface StudyLevelSelectionProps {
  onLevelSelect: (level: number) => void;
  selectedLevel: number | null;
}

// Utiliser les clés de traduction pour les descriptions de niveau
const levels = [
  { level: 0, descriptionKey: 'studyLevelSelection.levels.0' },
  { level: 1, descriptionKey: 'studyLevelSelection.levels.1' },
  { level: 2, descriptionKey: 'studyLevelSelection.levels.2' },
  { level: 3, descriptionKey: 'studyLevelSelection.levels.3' },
  { level: 4, descriptionKey: 'studyLevelSelection.levels.4' },
];

export const StudyLevelSelection: React.FC<StudyLevelSelectionProps> = ({
  onLevelSelect,
  selectedLevel,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [tempLevel, setTempLevel] = React.useState<number | null>(null); // Pas de pré-sélection

  const handleLevelSelect = (level: number) => {
    setTempLevel(level);
  };

  const handleConfirmClick = async () => {
    if (!currentUser) {
      console.error("Pas d'utilisateur connecté");
      return;
    }

    if (tempLevel === null) {
      console.error("Aucun niveau sélectionné");
      return;
    }

    onLevelSelect(tempLevel); // Navigation immédiate

    try {
      console.log("Envoi du niveau d'étude :", tempLevel);
      const response = await fetch('/api/user/registerOrUpdate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid: currentUser.uid, studyLevel: tempLevel }),
      });

      const data = await response.json();
      console.log("Niveau d'étude enregistré :", data);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du niveau d'étude :", error);
    }
  };

  return (
    <Layout>
      <div className="study-level-selection custom-top-padding">
        <h2>{t('studyLevelSelection.title')}</h2>
        <div className="levels-list">
          {levels.map((level) => (
            <div
              key={level.level}
              className={`level-card ${tempLevel === level.level ? 'selected' : ''}`}
              onClick={() => handleLevelSelect(level.level)}
            >
              <h3>
                <strong>{t(`studyLevelSelection.levelLabels.${level.level}`)}</strong>: {t(`studyLevelSelection.levelDescriptions.${level.level}`)}
              </h3>
            </div>
          ))}
        </div>
        <button
          onClick={handleConfirmClick}
          className="confirm-button"
          disabled={tempLevel === null} // Désactiver le bouton si aucun niveau n'est sélectionné
        >
          {t('studyLevelSelection.confirmButton')}
        </button>
      </div>
    </Layout>
  );
};
